<div class="container-fluid login-container p-0" style="height: 100%;">
    <div class="alert alert-danger" *ngIf="error"
        style="color: #721c24; background-color: #f8d7da; border-color: #f5c6cb;" role="alert">
        {{error}}
    </div>

    <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 pl-xl-0 pl-lg-0" style="height: 100%;" *ngIf="!error">
        <mat-expansion-panel [expanded]="true" class="map-panel" [hideToggle]="true" style="height: 100%;">
            <google-map style="height: 100%;" [zoom]="zoom" (mapInitialized)="mapReady()" [usePanning]="true">
                <ng-container *ngIf="directionsResults$ | async; as directionsResults">
                    <map-directions-renderer  *ngIf="shouldEnableDirection" [directions]="directionsResults"></map-directions-renderer>
                </ng-container>

                <map-marker *ngIf="!shouldEnableDirection" [position]="{lat: location?.lat, lng: location?.lng}">
                </map-marker>
            </google-map>
        </mat-expansion-panel>
    </div>
</div>
