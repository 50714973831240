<!-- style="z-index: 0" -->
<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
    <div class="container-fluid" style="padding: 0">
        <div class="navbar-wrapper flex-grow-1">
            <!-- <a class="navbar-brand" href="#">{{getTitle()}}</a> -->
            <ol class="breadcrumb">
                <li class="breadcrumb-item" *ngFor="let path of getParents()">
                    <a [routerLink]="path.path">{{ path.title }}</a>
                </li>
                <li class="breadcrumb-item active">{{ getTitle() }}</li>
            </ol>
        </div>

        <div>
            <div
                class="cus-form-group mb-0 pb-0 m-0"
                *ngIf="authenticationService.currentOrg"
            >
                <select
                    class="form-control theme-light-background"
                    (change)="selectHub()"
                    [(ngModel)]="selectedHub"
                    style="background-image: none"
                >
                    <option
                        *ngFor="let hub of authenticationService.hubs"
                        [value]="hub.id"
                    >
                        {{ hub.name }}
                    </option>
                </select>
            </div>
            <!-- <mat-form-field>
                <mat-select name="city" (ngModelChange)="selectHub($event)" [disableOptionCentering]="true"
                    panelClass="mat-select-raised" [(ngModel)]="selectedHub">
                    <mat-option *ngFor="let hub of authenticationService.hubs" [value]="hub.id">
                        {{hub.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
        </div>

        <button
            mat-raised-button
            class="navbar-toggler"
            type="button"
            (click)="sidebarToggle()"
        >
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>

        <div
            class="collapse navbar-collapse flex-grow-0 justify-content-end"
            id="navigation"
        >
            <ul class="navbar-nav">
                <li class="nav-item" *ngIf="authenticationService.currentOrg">
                    <a class="nav-link" [routerLink]="[pathToDashboard]">
                        <i class="material-icons">dashboard</i>
                        <p>
                            <span class="d-lg-none d-md-block">Stats</span>
                        </p>
                    </a>
                </li>
                <li
                    class="nav-item dropdown"
                    *ngIf="authenticationService.currentOrg"
                >
                    <!-- [routerLink]="[pathToNotifications]" -->
                    <a
                        class="nav-link"
                        style="cursor: default"
                        id="navbarDropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i class="material-icons">notifications</i>
                        <span
                            class="notification"
                            style="padding: 0 1px"
                            *ngIf="notService.totalUnread > 0"
                            >{{ notService.badgeString }}</span
                        >
                        <p>
                            <span class="d-lg-none d-md-block"
                                >Notifications</span
                            >
                        </p>
                    </a>
                    <div
                        class="dropdown-menu dropdown-menu-right pb-0"
                        aria-labelledby="navbarDropdownMenuLink"
                    >
                        <div
                            class="dropdown-item"
                            style="flex-direction: column; align-items: start"
                            *ngFor="let not of notService.nots | slice : 0 : 10"
                            (click)="onClick(not)"
                            [ngClass]="{
                                'status-enable': not.state !== 'read'
                            }"
                        >
                            <p class="mb-0" style="width: 100%">
                                <strong>{{ not.title }}</strong>
                                <span class="small float-right">{{
                                    not.when | async
                                }}</span>
                            </p>
                            <p class=".lead mb-0">{{ not.body }}</p>
                        </div>
                        <div
                            *ngIf="notService.nots.length === 0"
                            class="text-center"
                        >
                            <small>No new notifications</small>
                        </div>
                        <button
                            type="button"
                            [routerLink]="[pathToNotifications]"
                            class="btn btn-secondary btn-sm btn-lg btn-block"
                        >
                            View All
                        </button>
                    </div>
                </li>
                <li
                    class="nav-item"
                    title="Log Out"
                    (click)="logout()"
                    style="cursor: pointer"
                >
                    <a class="nav-link">
                        <i class="material-icons">power_settings_new</i>
                        <p>
                            <span class="d-lg-none d-md-block">Account</span>
                        </p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
