<div class="wrapper">
    <div
        class="sidebar bg-secondary"
        data-color="danger"
        data-background-color="white"
        data-image="./assets/img/sidebar-1.jpg"
    >
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <!-- <div
            *ngFor="let alert of alerts; let i = index"
            class="position-fixed"
            [ngStyle]="{ top: i * 45 + 'px' }"
            style="left: 0; right: 0; z-index: 3000"
        >
            <ngb-alert [type]="alert.type" (close)="closeAlert(alert)">{{
                alert.message
            }}</ngb-alert>
        </div> -->

        <router-outlet></router-outlet>
        <!-- <app-footer></app-footer> -->
        <div #buzzerContainer></div>
    </div>
    <notifier-container></notifier-container>
</div>
