import { Observable, timer } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import * as moment from 'moment';

export type Segment = 'ondemand' | 'outstation' | 'rental' | 'oneway';

export type EventType =
    'org_activation_status_change' |
    'ride_created' |
    'tripsheet_generated' |
    'tripsheet_generated' |
    'ride_started' |
    'ride_accepted' |
    'ride_completed' |
    'payment_received' |
    'enquiry';

export interface RideRelatedNotification {
    rideRequestId: string;
}

export interface OrgActivationStatusChangeNotification {
    event: 'org_activation_status_change';
    id: string;
    createdAt: string;
    orgId: string;
    orgName: string;
    state: string;
}

export interface RideCreatedNotification extends RideRelatedNotification {
    event: 'ride_created';
    id: string;
    createdAt: string;
    segment: Segment;
    userId: string;
    crn: string;
    state: string;
}

export interface TripSheetGeneratedNotification extends RideRelatedNotification {
    event: 'tripsheet_generated';
    id: string;
    createdAt: string;
    segment: Segment;
    userId: string;
    crn: string;
    state: string;
    estimate: string;
}

export interface RideStartedNotification extends RideRelatedNotification {
    event: 'ride_started';
    id: string;
    createdAt: string;
    segment: Segment;
    userId: string;
    crn: string;
    state: string;
}

export interface RideAcceptedNotification extends RideRelatedNotification {
    event: 'ride_accepted';
    id: string;
    createdAt: string;
    segment: Segment;
    userId: string;
    crn: string;
    state: string;
    driverName: string;
}

export interface RideCompletedNotification extends RideRelatedNotification {
    event: 'ride_completed';
    id: string;
    createdAt: string;
    segment: Segment;
    userId: string;
    crn: string;
    state: string;
    estimate: string;
}

export interface PaymentReceivedNotification extends RideRelatedNotification {
    event: 'payment_received';
    id: string;
    createdAt: string;
    segment: Segment;
    userId: string;
    crn: string;
    amount: number;
    state?: string;
}

export interface RideEnquiryNotification {
    id: string;
    createdAt: string;
    state: string;
    event: 'enquiry';
    enquiryId: string;
    segment: Segment;
    userId: string;
}

export type RemoteNotification =
    OrgActivationStatusChangeNotification |
    RideCreatedNotification |
    TripSheetGeneratedNotification |
    RideStartedNotification |
    RideAcceptedNotification |
    RideCompletedNotification |
    PaymentReceivedNotification |
    RideEnquiryNotification;

export class SourceNotification {
    id: string;
    event: EventType;
    createdAt: string;
    formattedTime: string;
    data: RemoteNotification;
    state: string;
    title: string;
    body: string;
    when: Observable<string>;

    constructor(doc: any) {
        this.id = doc.id;
        this.event = doc.event;
        this.createdAt = doc.createdAt;
        this.formattedTime = moment(doc.createdAt).format('ddd, MMM Do YY, hh:mm a');
        this.data = doc.data;
        this.state = doc.state || 'unread';
        this.title = getNotTitle(this);
        this.body = getNotBody(this);
        this.when = timer(0, 3000).pipe(
            map(() => moment(this.createdAt).fromNow()),
            distinctUntilChanged()
        );
    }
}

function getNotTitle(noti: SourceNotification) {
    const not = noti.data;
    switch (not.event) {
        case 'ride_created': {
            return `New ${not.segment} ride request`;
        }
        case 'ride_accepted': {
            return `Ride #${not.crn} accepted`;
        }
        case 'org_activation_status_change': {
            const actStatus = not.state === 'true' ? 'activated' : 'deactivated';
            return `Business ${actStatus}`;
        }
        case 'ride_started': {
            return `Ride started`;
            break;
        }
        case 'tripsheet_generated': {
            return `Trip sheet generated`;
        }
        case 'ride_completed': {
            return `Ride completed`;
        }
        case 'payment_received': {
            return `Payment received for ride ${not.crn}`;
        }
        case 'enquiry': {
            return `New ${not.segment} enquiry`;
        }
        default: {
            return null;
        }
    }
}

function getNotBody(noti: SourceNotification) {
    const not = noti.data;
    switch (not.event) {
        case 'ride_created': {
            return `You have a new ${not.segment} booking with crn ${not.crn}`;
        }
        case 'ride_accepted': {
            return `Ride request with CRN ${not.crn} accepted by ${not.driverName}.`;
        }
        case 'org_activation_status_change': {
            const actStatus = not.state === 'true' ? 'activated' : 'deactivated';
            return `Your business ${not.orgName} has been ${actStatus}.`;
        }
        case 'ride_started': {
            return `Ride ${not.crn} has started`;
            break;
        }
        case 'tripsheet_generated': {
            let estText = '';
            if (not.estimate && not.estimate.length > 0) {
                estText = ` Total amount is ₹${not.estimate}.`;
            }
            return `Trip sheet generated for ride ${not.crn}.${estText}`;
        }
        case 'ride_completed': {
            let estText = '';
            if (not.estimate && not.estimate.length > 0) {
                estText = ` Total amount is ₹${not.estimate}.`;
            }
            return `Ride ${not.crn} has been completed.${estText}`;
        }
        case 'payment_received': {
            return `₹${not.amount} received as payment for ride ${not.crn}`;
        }
        case 'enquiry': {
            return `You have a new ${not.segment} enquiry.`;
        }
        default: {
            return null;
        }
    }
}
