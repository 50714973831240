import { Injectable } from '@angular/core';
import { RideRequest } from '../models/ride-request';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RideRequestContextService {
    private rideRequestSource = new BehaviorSubject<RideRequest>(new RideRequest());

    rideRequest = this.rideRequestSource.asObservable();

    constructor() { }

    editRide(rideRequest: RideRequest) {
        this.rideRequestSource.next(rideRequest);
    }
}
