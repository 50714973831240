<div class="container-fluid login-container p-0">
    <div class="jumbotron shadow login-form-container">
        <form [formGroup]="accountForm">
            <img class="d-block mx-auto mb-3" src="/assets/images/ic_launcher.png" width="120px;" />
            <h5 class="text-center text-secondary mb-4">Create Veho Account</h5>

            <div class="feedback text-danger" *ngIf="accountForm.invalid && accountForm.errors">
                <div *ngIf="accountForm.errors.networkError">
                    Something went wrong. Please try again after some time.
                </div>
                <div *ngIf="accountForm.errors.userExists">
                    Another user with same email or phone number exists.
                </div>
            </div>

            <div class="form-group" [class.has-vn-error]="firstName.invalid && (firstName.dirty || firstName.touched)">
                <label for="firstName">First Name</label>
                <input type="text" id="firstName" name="firstName" class="form-control flat-control"
                    placeholder="e.g. Ravi" formControlName="firstName">
                <div class="feedback text-danger" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                    <div *ngIf="firstName.errors.requiredNotEmptyString">
                        Please provide your first name.
                    </div>
                </div>
            </div>

            <div class="form-group" [class.has-vn-error]="lastName.invalid && (lastName.dirty || lastName.touched)">
                <label for="lastName">Last Name</label>
                <input type="text" id="lastName" name="lastName" class="form-control flat-control"
                    placeholder="e.g. Kumar" formControlName="lastName">
                <div class="feedback text-danger" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                    <div *ngIf="lastName.errors.requiredNotEmptyString">
                        Please provide your last name.
                    </div>
                </div>
            </div>

            <div class="form-group" [class.has-vn-error]="email.invalid && (email.dirty || email.touched)">
                <label for="email">Email</label>
                <input type="text" id="email" name="email" class="form-control flat-control"
                    placeholder="e.g. ravi@example.com" formControlName="email">
                <div class="feedback text-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                    <div *ngIf="email.errors.required || email.errors.email">
                        Please provide a valid email address.
                    </div>

                </div>
            </div>

            <div class="form-group"
                [class.has-vn-error]="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)">
                <label for="phoneNumber">Phone Number</label>
                <input type="tel" id="phoneNumber" name="phoneNumber" class="form-control flat-control"
                    placeholder="Enter phone number" formControlName="phoneNumber">
                <div class="feedback text-danger"
                    *ngIf="accountForm.invalid && accountForm.errors && (phoneNumber.dirty || phoneNumber.touched)">
                    <div *ngIf="accountForm.errors.phoneNumber">
                        Please provide a valid phone number.
                    </div>
                </div>
            </div>

            <div>
                <button type="submit" class="btn btn-primary text-capitalize btn-block" [disabled]="!accountForm.valid"
                    (click)="createAccount();">
                    <span>Register</span>
                    <div class="spinner-border spinner-border-sm text-light ml-2" role="status" *ngIf="isLoading">
                        <span class="sr-only">Loading...</span>
                    </div>
                </button>
            </div>
        </form>
    </div>
</div>
