<div class="logo">
    <a href="{{authenticationService.currentOrg ? authenticationService.currentOrg.homePage: ''}}" class="simple-text mb-2">
        <div class="logo-img">
            <img *ngIf="!authenticationService.currentOrg || !authenticationService.currentOrg.logoHref" src="/assets/images/ic_launcher.png" />
            <img *ngIf="authenticationService.currentOrg && authenticationService.currentOrg.logoHref" src="{{authenticationService.currentOrg.logoHref}}" />
        </div>
        <span style="vertical-align: middle;" class="ml-2">{{authenticationService.currentOrg ? authenticationService.currentOrg.displayName : 'Veho Business' }}</span>
    </a>
</div>
<div class="sidebar-wrapper">
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of routes" class="{{menuItem.class}} nav-item">
            <a class="nav-link" *ngIf="menuItem.path" [routerLink]="[menuItem.path]">
                <i class="material-icons">{{menuItem.icon}}</i>
                <span class="notification" style="padding: 0 1px;" *ngIf="menuItem.path === '/notifications' && notService.totalUnread > 0">{{ notService.badgeString }}</span>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
        <li *ngIf="isMobileMenu()" style="cursor: pointer;" routerLinkActive="active" (click)="logout();"
            class="nav-item">
            <a class="nav-link">
                <i class="material-icons">power_settings_new</i>
                <p>Log Out</p>
            </a>
        </li>
    </ul>
</div>
