import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface RouteInfo {
    path?: string;
    title?: string;
    icon?: string;
    class?: string;
    showInSideBar?: boolean;
    parents?: RouteInfo[];
    enabled?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class RoutesService {
    private ROUTES: RouteInfo[] = [
        { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '', enabled: true },
        { path: '/categories', title: 'Categories', icon: 'category', class: '', enabled: true },
        {
            path: '/categories/new', title: 'New', showInSideBar: false,
            parents: [
                { path: '/categories', title: 'Categories' }
            ]
        },
        {
            path: '/categories/update', title: 'Update', showInSideBar: false,
            parents: [
                { path: '/categories', title: 'Categories' }
            ]
        },
        {
            path: '/categories/:id/rateCards', title: 'Rate cards', showInSideBar: false,
            parents: [
                { path: '/categories', title: 'Categories' }
            ]
        },
        { path: '/hubs', title: 'Cities', icon: 'location_city', class: '', enabled: true },
        { path: '/drivers', title: 'Drivers', icon: 'group', class: '', enabled: true },
        {
            path: '/drivers/new', title: 'New', showInSideBar: false,
            parents: [
                { path: '/drivers', title: 'Drivers' }
            ]
        },
        {
            path: '/drivers/update', title: 'Update', showInSideBar: false,
            parents: [
                { path: '/drivers', title: 'Drivers' }
            ]
        },
        { path: '/rateCards', title: 'Rate Cards', icon: 'attach_money', class: '', enabled: true },
        { path: '/vehicles', title: 'Vehicles', icon: 'directions_car', class: '', enabled: true },
        {
            path: '/vehicles/new', title: 'New', showInSideBar: false,
            parents: [
                { path: '/vehicles', title: 'Vehicles' }
            ]
        },
        {
            path: '/vehicles/update', title: 'Update', showInSideBar: false,
            parents: [
                { path: '/vehicles', title: 'Vehicles' }
            ]
        },
        { path: '/rideRequests', title: 'Ride Requests', icon: 'check_box', class: '', enabled: true },
        {
            path: '/rideRequests/new', title: 'New', showInSideBar: false,
            parents: [
                { path: '/rideRequests', title: 'Ride Requests' }
            ]
        },
        {
            path: '/rideRequests/update', title: 'Update', showInSideBar: false,
            parents: [
                { path: '/rideRequests', title: 'Ride Requests' }
            ]
        },
        { path: '/business', title: 'Businesses', icon: 'business', class: '', enabled: true },
        { path: '/enquiries', title: 'Enquiries', icon: 'contact_support', class: '', enabled: true },
        { path: '/trackDrivers', title: 'Track Drivers', icon: 'location_on', class: '', enabled: true },
        { path: '/reports', title: 'Reports', icon: 'bar_chart', class: '', enabled: true },
        { class: 'separator' },
        { path: '/account', title: 'Account', icon: 'person', class: '', enabled: true },
        { path: '/notifications', title: 'Notifications', icon: 'notifications', class: '', enabled: true },
        { path: '/settings', title: 'Settings', icon: 'settings', class: '', enabled: true }
    ];

    private pendingActivationRoutes: RouteInfo[] = [
        { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '', enabled: true }
    ];

    private noHubRoutes: RouteInfo[] = [
        { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '', enabled: true },
        { path: '/hubs', title: 'Cities', icon: 'location_city', class: '', enabled: true }
    ];

    private routeSubject: BehaviorSubject<RouteInfo[]> = new BehaviorSubject([]);
    routes: Observable<RouteInfo[]> = this.routeSubject.asObservable();

    constructor() { }

    private onboardingRoutes = [{
        path: '/orgs',
        title: 'Setup business',
        icon: 'business',
        class: '',
        enabled: true
    }];

    setOnboardingMenu() {
        this.routeSubject.next(this.onboardingRoutes);
    }

    setPendingActivationMenu() {
        this.routeSubject.next(this.pendingActivationRoutes);
    }

    setDefaultMenu() {
        this.routeSubject.next(this.ROUTES);
    }

    setNoHubMenu() {
        this.routeSubject.next(this.noHubRoutes);
    }
}
