<div class="container-fluid login-container p-0">
    <div class="jumbotron shadow login-form-container">
        <form [formGroup]="authForm">
            <!-- <img class="d-block mx-auto mb-3" src="https://angular.io/assets/images/logos/angular/angular.svg" width="150px;" /> -->
            <img class="d-block mx-auto mb-3" src="/assets/images/ic_launcher.png" width="120px;" />
            <h5 class="text-center text-secondary mb-4">Veho Business</h5>

            <div class="feedback text-danger" *ngIf="authForm.invalid && (phoneNumber.dirty || phoneNumber.touched)">
                <div *ngIf="authForm.errors.networkError">
                    Something went wrong. Please try again after some time.
                </div>
                <div *ngIf="authForm.errors.invalidCredentials">
                    Please check the verification code sent to your number and try again.
                </div>
                <div *ngIf="authForm.errors.phoneNumberNotRegistered">
                    Couldn't find your Veho account.
                </div>
                <div *ngIf="authForm.errors.password">
                    Please provide the verification code sent to your number.
                </div>
            </div>
            <div class="form-group"
                [class.has-vn-error]="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)">
                <label for="phoneNumber">Phone Number</label>
                <input type="tel" id="phoneNumber" name="phoneNumber" class="form-control flat-control"
                    placeholder="Enter phone number" formControlName="phoneNumber">
                <div class="feedback text-danger"
                    *ngIf="authForm.invalid && (phoneNumber.dirty || phoneNumber.touched)">
                    <div *ngIf="authForm.errors.required || authForm.errors.phoneNumber">
                        Please provide a valid phone number.
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="isWaitingForOtp"
                [class.has-vn-error]="password.invalid && (password.dirty || password.touched)">
                <label for="password">Verification Code</label>
                <input type="text" id="password" name="password" aria-describedby="passwordHelp"
                    class="form-control flat-control" placeholder="Enter verification code" formControlName="password">
                <small id="passwordHelp" class="form-text text-muted">We have sent a verification code to
                    {{getPhoneNumber()}}.</small>
            </div>

            <div *ngIf="!isWaitingForOtp">
                <button type="submit" class="btn btn-primary text-capitalize btn-block" [disabled]="!authForm.valid"
                    (click)="createSession();">Get Otp</button>
            </div>

            <div *ngIf="!isWaitingForOtp">
                <a [routerLink]="['/onboarding']" class="small-text no-decoration" [queryParams]="{debug: true}">
                    <small>Don't have an account? Create one</small>
                </a>
            </div>

            <div *ngIf="isWaitingForOtp">
                <button type="button" class="btn btn-danger text-capitalize mr-2" (click)="resetForm();">Cancel</button>

                <button type="submit" class="btn btn-primary text-capitalize" [disabled]="!password.valid"
                    (click)="login();">Sign In</button>
            </div>
        </form>
    </div>
</div>
