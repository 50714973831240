import { AbstractControl } from '@angular/forms';

declare var intlTelInputUtils: any;

export function validatePhoneNumber(control: AbstractControl) {
    const countryCode = control.get('countryCode');
    const phoneNumber = control.get('phoneNumber');

    if (/^\d{10}$/.test(phoneNumber.value) && intlTelInputUtils.isValidNumber(`${countryCode.value}${phoneNumber.value}`)) {
        return null;
    } else {
        return { phoneNumber: true };
    }
}
