import { Injectable } from '@angular/core';
import { BehaviorSubject, timer, throwError, Observable, of } from 'rxjs';
import { RemoteNotification, SourceNotification } from '../models/notification';
import { map, distinctUntilChanged, catchError, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { AuthenticationService } from './authentication.service';
import { HttpParams } from '@angular/common/http';
import { VehoWebService } from './veho-web.service';
import { ListingResponse } from '../models/listing-response';

export interface Alert {
    type: 'success' | 'info' | 'warning' | 'danger' | 'primary' | 'secondary' | 'dark' | 'light';
    message: string;
    ttl?: number;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private alertSubject = new BehaviorSubject<Alert>(null);
    public alerts = this.alertSubject.asObservable();

    nots: SourceNotification[] = [];
    totalUnread = 0;
    badgeString = '';
    handleError: any;

    private latestResponse: ListingResponse<SourceNotification> = null;

    constructor(private notifierService: NotifierService, private authService: AuthenticationService, private webService: VehoWebService) {
    }

    init(sns: SourceNotification[], totalUnread: number) {
        this.updateBadge(totalUnread);
        this.nots.push.apply(this.nots, sns);
    }

    private updateBadge(totalUnread: number) {
        this.totalUnread = totalUnread;
        if (totalUnread > 0) {
            if (totalUnread >= 100) {
                this.badgeString = '99+';
            } else {
                this.badgeString = totalUnread.toString();
            }
        } else {
            this.badgeString = '';
        }
    }

    notify(alert: Alert, ttl: number = 5000) {
        if (!alert.ttl) {
            alert.ttl = ttl;
        }

        if (alert.message) {
            this.alertSubject.next(alert);
        }
    }

    notifySuccess(message: string, ttl: number = 5000) {
        this.notify({ type: 'success', message: message, ttl: ttl });
    }

    notifyError(message: string, ttl: number = 5000) {
        this.notify({ type: 'danger', message: message, ttl: ttl });
    }

    showPushNotification(not: RemoteNotification) {
        if (not && not.event) {
            const srn = new SourceNotification({
                data: not,
                createdAt: not.createdAt,
                event: not.event,
                id: not.id,
                state: 'unread'
            });

            this.updateBadge(this.totalUnread + 1);
            this.nots.unshift(srn);
            this.displayNot(srn);
        }
    }

    displayNot(not: SourceNotification) {
        switch (not.event) {
            case 'payment_received':
            case 'ride_completed': {
                this.notifierService.notify('success', not.body);
                break;
            }
            default: {
                this.notifierService.notify('info', not.body);
                break;
            }
        }
    }

    load(page: number = 1) {
        const token = this.authService.currentToken.accessToken;
        const params = new HttpParams({ fromObject: { page: page.toString(), size: '10', 'filter[state]': 'unread' } });

        this.webService
            .getNotifications(token, params)
            .pipe(catchError(error => {
                return throwError(error);
            }))
            .subscribe(res => {
                this.latestResponse = res;
                this.init(res.items.slice(), res.total);
            });
    }

    tryUpdateReadStatus(not: SourceNotification) {
        if (not.state === 'read') {
            return of(null);
        }

        const token = this.authService.currentToken.accessToken;
        this.webService
            .updateNotificationReadStatus(token, not.id, { state: 'read' })
            .pipe(
                tap(_ => not.state = 'read'),
                catchError(err => null)
            )
            .subscribe(_ => {
                this.removeNot(not);
            });

        return of(null);
    }

    private removeNot(not: SourceNotification) {
        let index = this.nots.indexOf(not);
        if (index === -1) {
            // Try find by id
            const n = this.nots.find(no => no.id === not.id);
            index = n ? this.nots.indexOf(n) : -1;
        }

        if (index > -1) {
            this.nots.splice(index, 1);
            this.updateBadge(this.totalUnread - 1);

            // Check if nots contain less than 10 items.
            // If so fetch 1 more page

            if (this.nots.length < 10 && this.latestResponse && this.latestResponse._links.next) {
                const nextPage = this.latestResponse.page + 1;
                this.load(nextPage);
            }
        }
    }
}
