// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseUrl: "http://localhost:4200/beta",
    firebaseConfig: {
        apiKey: "AIzaSyAw0Y7YrvKi7ntkIjnimmLfmUZAtBx3T7s",
        authDomain: "veho-87e78.firebaseapp.com",
        databaseURL: "https://veho-87e78.firebaseio.com",
        projectId: "veho-87e78",
        storageBucket: "veho-87e78.appspot.com",
        messagingSenderId: "697224472816",
        appId: "1:697224472816:web:b79247246dad4fa0273a35",
        measurementId: "G-NZDXPS3KF8",
    },
    firebasePublicValidKey:
        "BB8SdH5cKyzpH-_gOLcgiccDfSqUOUasde-XZgws4J6rQ11aVSdzwrSPbLJiZbmCxEEps-pC8X9zAiW4692SzIw",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
