import {
    Component,
    OnInit,
    OnDestroy,
    ElementRef,
    ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription, BehaviorSubject } from "rxjs";
import { VehoWebService } from "src/app/services/veho-web.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { NotificationService } from "src/app/services/notification.service";
import { HttpParams } from "@angular/common/http";
import { ListingResponse } from "src/app/models/listing-response";
import { PageEvent } from "@angular/material/paginator";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { MatTabsModule } from "@angular/material/tabs";

@Component({
    selector: "app-driver-details",
    templateUrl: "./driver-details.component.html",
    styleUrls: ["./driver-details.component.less"],
})
export class DriverDetailsComponent implements OnInit, OnDestroy {
    public subscription: Subscription;
    driverDetails = [];
    displayedColumns: string[] = [
        "hub",
        "crn",
        "segment",
        "customerName",
        "pickupDate",
        "pickupTime",
        "pickupLocation",
    ];

    fname = "";
    lname = "";
    coutnry = "";
    number = "";
    driverlenth: number = 0;
    total: number;
    totalPages: number;
    page: number;
    pageSize = 10;
    currentPage = 0;
    readonly tabs = [
        {
            name: "driverAssigned",
            displayName: "Driver Assigned",
            states: ["driverAssigned"],
        },
        {
            name: "inProgress",
            displayName: "In Progress",
            states: ["clientLocated", "started"],
        },
        {
            name: "completed",
            displayName: "Completed",
            states: ["tripSheetGenerated", "invoiceGenerated", "completed"],
        },
        {
            name: "cancelled",
            displayName: "Cancelled",
            states: [
                "cancelledByCustomer",
                "cancelledByDriver",
                "cancelledByProvider",
            ],
        },
        {
            name: "all",
            displayName: "All",
            states: [
                "queued",
                "accepted",
                "driverAssigned",
                "clientLocated",
                "started",
                "tripSheetGenerated",
                "invoiceGenerated",
                "completed",
                "cancelledByCustomer",
                "cancelledByDriver",
                "cancelledByProvider",
            ],
        },
    ];
    readonly supportedPageSizes = [10, 25, 50].sort();
    isLoading = false;
    hasError = false;

    constructor(
        private vehoWebService: VehoWebService,
        private authService: AuthenticationService,
        private notificationService: NotificationService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        // Extract profile ID from the route parameters
        this.route.paramMap.subscribe((params) => {
            const id = params.get("id");
            if (id) {
                this.loadUserDetails(id, this.currentPage + 1, this.pageSize);
            } else {
                console.error("User ID is missing from the URL.");
            }
        });
    }

    loadUserDetails(profileId: string, page: number, size: number) {
        if (!profileId) {
            console.error("Profile ID is missing, cannot fetch details.");
            return;
        }

        const accessToken = this.authService.currentToken.accessToken;
        const params = new HttpParams()
            .set("page", page.toString())
            .set("size", size.toString());

        this.vehoWebService
            .getUserdetails(profileId, accessToken, params)
            .subscribe(
                (response: ListingResponse<any>) => {
                    this.driverlenth = response.items.length;
                    this.driverDetails = response.items;
                    this.total = response.total;
                    this.totalPages = response.totalPages;
                    this.page = response.page;
                    this.currentPage = response.page - 1;
                    this.fname = this.driverDetails[0]?.driver?.firstName || "";
                    this.lname = this.driverDetails[0]?.driver?.lastName || "";
                    this.coutnry =
                        this.driverDetails[0]?.driver?.phone?.countryCode || "";
                    this.number =
                        this.driverDetails[0]?.driver?.phone?.number || "";
                },
                (err) => {
                    this.notificationService.notifyError(
                        "Failed to load driver details."
                    );
                    console.error("Error loading driver details", err);
                }
            );
    }

    onPageSizeChange(event: PageEvent) {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        const profileId = this.route.snapshot.paramMap.get("id");
        if (profileId) {
            // Adjust API call for the new page and page size
            this.loadUserDetails(
                profileId,
                this.currentPage + 1,
                this.pageSize
            );
        }
    }

    goBack() {
        history.back();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    driver() {
        console.log("Driver details:", this.driverDetails);
    }
}
