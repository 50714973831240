import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Hub } from 'src/app/models/hub';
import { RoutesService, RouteInfo } from 'src/app/services/routes.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RemoteNotification, SourceNotification, RideRelatedNotification, RideEnquiryNotification } from 'src/app/models/notification';
import * as moment from 'moment';
import { timer, Observable, throwError } from 'rxjs';
import { map, tap, distinctUntilChanged, catchError, zip } from 'rxjs/operators';
import { RideRequestContextService } from 'src/app/ride-requests/ride-request-context.service';
import { VehoWebService } from 'src/app/services/veho-web.service';

declare var $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.less']
})
export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    shouldShowNotifications = false;

    public pathToDashboard = '/dashboard';
    public pathToNotifications = '/notifications';

    selectedHub: string;

    routes: RouteInfo[];
    nameParam: string;

    constructor(location: Location, private routesService: RoutesService,
        private element: ElementRef, private router: Router, private route: ActivatedRoute,
        public authenticationService: AuthenticationService,
        public notService: NotificationService,
        private vehoWebService: VehoWebService,
        private rideContextService: RideRequestContextService) {
        this.location = location;
        this.sidebarVisible = false;

        this.authenticationService.selectedHubAsObservable.subscribe(hub => {
            if (hub && hub.id !== this.selectedHub) {
                this.selectedHub = hub.id;
            }
        });

        this.routesService.routes.subscribe(val => {
            this.routes = val;
            this.listTitles = this.routes.filter(listTitle => listTitle);
        });
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe((event) => {
            this.sidebarClose();
            const $layer: any = document.getElementsByClassName('close-layer')[0];

            if ($layer) {
                $layer.remove();
                this.mobile_menu_visible = 0;
            }
        });

        this.route.queryParams.subscribe(params => {
            this.nameParam = params.name;
        });
    }

    selectHub() {
        if (this.selectedHub) {
            const hub = this.authenticationService.hubs.find(h => this.selectedHub === h.id);
            this.authenticationService.selectHub(hub);
        }
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    }

    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    }

    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        const $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible === 1) {
            // $('html').removeClass('nav-open');
            body.classList.remove('nav-open');

            const $layer: any = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
            }

            setTimeout(function () {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function () {
                $toggle.classList.add('toggled');
            }, 430);

            const $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            } else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function () {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function () { // assign a function
                body.classList.remove('nav-open');
                this.mobile_menu_visible = 0;
                $layer.classList.remove('visible');
                setTimeout(function () {
                    $layer.remove();
                    $toggle.classList.remove('toggled');
                }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    }

    getTitle() {
        let path = this.location.prepareExternalUrl(this.location.path());
        if (path.charAt(0) === '#') {
            path = path.slice(2);
        }

        if (/categories\/.*\/rateCards/.test(path)) {
            return 'Ratecards';
        } else if (path.indexOf('rateCards') > -1) {
            return 'Ratecards';
        }

        for (let item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === path) {
                return this.listTitles[item].title;
            }
        }

        return 'Admin Console';
    }

    getParents() {
        let path = this.location.prepareExternalUrl(this.location.path());
        if (path.charAt(0) === '#') {
            path = path.slice(2);
        }

        if (/categories\/.*\/rateCards/.test(path)) {
            const parents = this.listTitles.filter(lt => lt.path === '/categories/:id/rateCards')[0].parents.slice();
            if (this.nameParam && !parents.find(p => p.title === this.nameParam)) {
                parents.push({ path: '/categories', title: this.nameParam });
            }

            return parents;
        }

        for (let item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === path) {
                return this.listTitles[item].parents;
            }
        }

        return [];
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigateByUrl('/signin');
    }

    private onRideRelated(not: SourceNotification) {
        const token = this.authenticationService.currentToken.accessToken;
        let obs: Observable<any> = this.vehoWebService
            .getRideRequest((<RideRelatedNotification>not.data).rideRequestId, this.authenticationService.currentToken.accessToken, null)
            .pipe(catchError(error => {
                return throwError(error);
            }));

        const updateReadStatus = this.notService.tryUpdateReadStatus(not);

        obs = obs.pipe(
            zip(updateReadStatus)
        );

        obs.subscribe(pair => {
            let rideRequest = pair;
            if (Array.isArray(pair)) {
                rideRequest = pair[0];
                not.state = 'read';
            }

            this.router.navigateByUrl(`/rideRequests/${rideRequest.id}`);
        });
    }

    private onRideEnquiryRelated(not: SourceNotification) {
        this.notService.tryUpdateReadStatus(not)
            .subscribe(pair => {
                let rideRequest = pair;
                if (Array.isArray(pair)) {
                    rideRequest = pair[0];
                    not.state = 'read';
                }

                this.router.navigate(['/enquiries'], { queryParams: { id: (not.data as RideEnquiryNotification).enquiryId } });
            });
    }

    onClick(not: SourceNotification) {
        const token = this.authenticationService.currentToken.accessToken;
        switch (not.event) {
            case 'ride_created':
            case 'ride_accepted':
            case 'ride_started':
            case 'ride_completed':
            case 'tripsheet_generated':
            case 'payment_received': {
                this.onRideRelated(not);
                break;
            }
            case 'enquiry': {
                this.onRideEnquiryRelated(not);
                break;
            }
            default: {
                this.notService
                    .tryUpdateReadStatus(not)
                    .subscribe(_ => { });
                break;
            }
        }
    }
}
