import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';
import { HttpClient } from '@angular/common/http';
import { VehoWebService } from './veho-web.service';
import { tap } from 'rxjs/operators';
import { AuthResponse } from '../models/auth-response';
import { Token } from '../models/token';
import { Organization } from '../models/organization';
import { Hub } from '../models/hub';
import { RoutesService } from './routes.service';
import { deleteToken, getToken, Messaging } from '@angular/fire/messaging';

declare var Fingerprint2: any;
declare var UAParser: any;

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUserStream: Observable<User>;
    private currentOrgSubject: BehaviorSubject<Organization>;
    public currentOrgStream: Observable<Organization>;
    public hubs: Hub[];
    private selectedHubSubject: BehaviorSubject<Hub>;
    public selectedHubAsObservable: Observable<Hub>;

    private deviceInfo: any;
    private deviceId: string;
    private deviceMake: string;
    private deviceModel: string;

    constructor(private http: HttpClient, private routesService: RoutesService, private vehoWebService: VehoWebService, private messaging: Messaging) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUserStream = this.currentUserSubject.asObservable();
        this.currentOrgSubject = new BehaviorSubject<Organization>(JSON.parse(localStorage.getItem('org')));
        this.currentOrgStream = this.currentOrgSubject.asObservable();
        this.hubs = [];
        this.selectedHubSubject = new BehaviorSubject<Hub>(null);
        this.selectedHubAsObservable = this.selectedHubSubject.asObservable();

        const uap = new UAParser();
        this.deviceInfo = uap.getResult();
        this.deviceMake = `${this.deviceInfo.os.name} ${this.deviceInfo.os.version}`;
        this.deviceModel = `${this.deviceInfo.browser.name}@${this.deviceInfo.browser.version}`;

        Fingerprint2.get((components) => {
            const values = components.map(function (component) { return component.value; });
            const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
            this.deviceId = fingerprint;
        });
    }

    public get currentUser(): User {
        return this.currentUserSubject.value;
    }

    public get currentToken(): Token {
        if (localStorage.getItem('token')) {
            return JSON.parse(localStorage.getItem('token'));
        } else {
            return null;
        }
    }

    public get currentOrg(): Organization {
        if (localStorage.getItem('org')) {
            const org = JSON.parse(localStorage.getItem('org'));
            this.currentOrgSubject.next(org);
            return org;
        } else {
            this.currentOrgSubject.next(null);
            return null;
        }
    }

    public set currentOrg(org: Organization) {
        localStorage.setItem('org', JSON.stringify(org));
        this.currentOrgSubject.next(org);
    }

    public get selectedHub() {
        return this.selectedHubSubject.value;
    }

    addHub(hub: Hub) {
        if (hub) {
            this.hubs.push(hub);
            if (!this.selectedHub) {
                this.selectedHubSubject.next(hub);
                this.routesService.setDefaultMenu();
            }
        }
    }

    selectHub(hub: Hub) {
        if (hub) {
            this.selectedHubSubject.next(hub);
        }
    }

    createSession(phoneNumber: string, countryCode: string = '91') {
        return this.vehoWebService.createSession(phoneNumber, countryCode, this.deviceId, this.deviceMake, this.deviceModel);
    }

    createAccount(fn: string, ln: string, email: string, pn: string, cc: string = '91') {
        return this.vehoWebService.createAccount(fn, ln, email, pn, cc);
    }

    login(phoneNumber: string, countryCode: string = '91', otp: string) {
        return this.vehoWebService.login(phoneNumber, countryCode, otp)
            .pipe(tap((resp: AuthResponse) => {
                if (resp.user && resp.accessToken) {
                    localStorage.setItem('currentUser', JSON.stringify(resp.user));
                    this.currentUserSubject.next(resp.user);
                    delete resp.user;
                    localStorage.setItem('token', JSON.stringify(resp));
                }
            }));
    }

    logout() {
        // remove fcm token & user data from local storage
        deleteToken(this.messaging).then(() => {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('token');
            localStorage.removeItem('org');
            this.currentUserSubject.next(null);
            this.currentOrgSubject.next(null);
          }).catch((error) => {
            console.error('Failed to delete token:', error);
          });
        // this.afMessaging.getToken
        //     .pipe(mergeMap(token => this.afMessaging.deleteToken(token)))
        //     .subscribe(
        //         (token) => { console.log('Deleted!'); },
        //     );
       
    }
}
