import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { ListItemDirective } from './list-item.directive';
import { PlacesSearchFormControlComponent } from './places-search-form-control/places-search-form-control.component';
import { MapsLoaderComponent } from './places-search-form-control/maps-loader/maps-loader.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { ProductSelectListFormControlComponent } from './product-select-list-form-control/product-select-list-form-control.component';
import { VirtualizedSelectListComponent } from './virtualized-select-list/virtualized-select-list.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ProductListDialogComponent } from './product-select-list-form-control/product-list-dialog/product-list-dialog.component';
import { LocationFormControlComponent } from './location-form-control/location-form-control.component';
import { LocationDialogComponent } from './location-form-control/location-dialog/location-dialog.component';
import { PhoneNumberFormControlComponent } from './phone-number-form-control/phone-number-form-control.component';
import { DriverSelectListFormControlComponent } from './driver-select-list-form-control/driver-select-list-form-control.component';
import { DriverListDialogComponent } from './driver-select-list-form-control/driver-list-dialog/driver-list-dialog.component';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        MatSelectModule,
        ReactiveFormsModule,
        NgbCarouselModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        GoogleMapsModule,
        ScrollingModule,
        MatDialogModule,
        MatInputModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ImageGalleryComponent,
        ListItemDirective,
        PlacesSearchFormControlComponent,
        MapsLoaderComponent,
        VirtualizedSelectListComponent,
        ProductSelectListFormControlComponent,
        ProductListDialogComponent,
        LocationFormControlComponent,
        LocationDialogComponent,
        PhoneNumberFormControlComponent,
        DriverSelectListFormControlComponent,
        DriverListDialogComponent
    ],
    exports: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ListItemDirective,
        PlacesSearchFormControlComponent,
        VirtualizedSelectListComponent,
        ProductSelectListFormControlComponent,
        LocationFormControlComponent,
        PhoneNumberFormControlComponent,
        DriverSelectListFormControlComponent
    ]
})
export class ComponentsModule { }
