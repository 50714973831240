import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { VehoWebService } from './veho-web.service';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { environment } from '../../environments/environment';
import { NotificationService } from './notification.service';
import { RemoteNotification } from '../models/notification';
import { initializeApp } from '@angular/fire/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import { getToken, Messaging } from '@angular/fire/messaging';

@Injectable({
    providedIn: 'root'
})
export class MessagingService {
   // private messaging: Messaging;
    private currentMessage = new BehaviorSubject<any> (null);
    messages = this.currentMessage.asObservable();

    constructor(
        private authService: AuthenticationService,
        private webService: VehoWebService,
        private notificationService: NotificationService,
        private messaging: Messaging
    ) {
        // try {
        //     //const app = initializeApp(environment.firebaseConfig);
        //     //this.messaging = getMessaging(app);
        //    // this.messaging.
        //     //this.messaging.usePublicVapidKey('BB8SdH5cKyzpH-_gOLcgiccDfSqUOUasde-XZgws4J6rQ11aVSdzwrSPbLJiZbmCxEEps-pC8X9zAiW4692SzIw');
        // } catch (error) {
        //     console.log(error);
        // }
       
    }

    requestPermission() {
        if (!this.messaging) {
            return;
        }
        // this.messaging
        Notification
            .requestPermission()
            .then((permission) => {
                return getToken(this.messaging, { vapidKey: environment.firebasePublicValidKey })
                //return this.messaging.getToken();
            })
            .then(token => {
                const accessToken = this.authService.currentToken.accessToken;
                this.webService
                    .updateFCMToken(accessToken, { registrationToken: token })
                    .subscribe(resp => {
                        this.listenForMessages();
                    });
            })
            .catch(error => {
                if (error.code === 'messaging/permission-default' || error.code === 'messaging/permission-blocked') {
                    const parts = [
                        'Notification permission denied.',
                        'This permission allow us to show important notifications even when your browser is closed. Consider allowing notifications for a better experience.'
                    ];
                    this.notificationService.notifyError(parts.join('\n'), 15000);
                }

                console.log(error);
            });
    }

    listenForMessages() {
        if (!this.messaging) {
            return;
        }
        // this.messaging.onMessage(message => {
        //     this.currentMessage.next(message.data);
        // });
        onMessage(this.messaging, (payload) => {
          // console.log('Message received: ', payload);
            this.currentMessage.next(payload.data);  // Emit the message via the BehaviorSubject
          });
    }
}
