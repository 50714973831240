import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { validatePhoneNumber } from '../_validators/phone-number.validator';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

declare var intlTelInput: any;
declare var intlTelInputUtils: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
    authForm: FormGroup;
    returnUrl: string;
    itiInstance: any;
    isWaitingForOtp = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private renderer: Renderer2,
        private authenticationService: AuthenticationService) { }

    initControls() {
        const input = document.querySelector('#phoneNumber');
        this.itiInstance = intlTelInput(input, {
            autoPlaceholder: 'aggressive',
            formatOnDisplay: true,
            initialCountry: 'IN',
            nationalMode: true,
            autoFormat: true,
            customPlaceholder: (selectedCountryPlaceholder, selectedCountryData) => {
                if (selectedCountryData.iso2 === 'in') {
                    return 'e.g. ' + selectedCountryPlaceholder.replace(/\D/g, '').substr(1, 10);
                } else {
                    return 'e.g. ' + selectedCountryPlaceholder.replace(/\D/g, '');
                }
            },
            preferredCountries: ['IN', 'US']
        });

        this.countryCode.setValue(`+${this.itiInstance.getSelectedCountryData().dialCode}`);
        const self = this;
        input.addEventListener('countrychange', function () {
            self.countryCode.setValue(`+${self.itiInstance.getSelectedCountryData().dialCode}`);
        });

        this.route.queryParams.subscribe(params => {
            const q = params['q'];
            if (q) {
                try {
                    const ph = JSON.parse(atob(decodeURIComponent(q)));
                    this.countryCode.setValue(ph.cc);
                    this.phoneNumber.setValue(ph.ph);
                    this.isWaitingForOtp = true;
                } catch (ex) {
                    console.log(ex);
                    // Do nothing
                }
            }
        });
    }

    ngOnInit() {
        this.authForm = this.formBuilder.group({
            countryCode: ['',
                Validators.required
            ],
            phoneNumber: ['', [
                Validators.required
            ]],
            password: ['']
        }, { validator: validatePhoneNumber });

        this.initControls();
    }

    get phoneNumber() {
        return this.authForm.get('phoneNumber');
    }

    get countryCode() {
        return this.authForm.get('countryCode');
    }

    get password() {
        return this.authForm.get('password');
    }

    getPhoneNumber() {
        return `${this.countryCode.value}-${this.phoneNumber.value}`;
    }

    setError(key: string) {
        const error = {};
        error[key] = true;
        this.authForm.setErrors(error);
    }

    resetForm() {
        this.isWaitingForOtp = false;
        this.countryCode.enable();
        this.phoneNumber.enable();
    }

    handleError(error: any) {
        if ('NETWORK_ERROR' === error.errorCode) {
            this.setError('networkError');
        } else if (!this.isWaitingForOtp && error.status === 404 && error.error.errorCode === 'USER_NOT_FOUND') {
            this.setError('phoneNumberNotRegistered');
        } else if (error.status === 404 && error.error.errorCode === 'USER_NOT_FOUND') {
            this.setError('invalidCredentials');
        }

        return throwError(error);
    }

    createSession() {
        const self = this;
        if (this.authForm.valid) {
            this.authenticationService.createSession(this.phoneNumber.value, this.countryCode.value.trim().replace('+', ''))
                .pipe(catchError(this.handleError.bind(this)))
                .subscribe(resp => {
                    // Succeeded
                    self.isWaitingForOtp = true;
                    self.countryCode.disable();
                    self.phoneNumber.disable();
                    setTimeout(() => self.renderer.selectRootElement('#password').focus(), 0);
                });
        }
    }

    login() {
        const pwd = this.password.value;
        if (!pwd || pwd.trim().length === 0) {
            this.setError('password');
        } else {
            this.authenticationService.login(
                this.phoneNumber.value,
                this.countryCode.value.trim().replace('+', ''),
                this.password.value.trim()
            ).pipe(catchError(this.handleError.bind(this)))
                .subscribe(resp => {
                    if(!resp.groups.length){
                        this.setError('phoneNumberNotRegistered');
                        return;
                    }
                    this.router.navigateByUrl('/');
                });
        }
    }
}
