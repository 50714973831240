import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VehoWebService } from '../services/veho-web.service';
import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { TrackingInfo } from '../models/user';
import { Title } from '@angular/platform-browser';
import { MapDirectionsService } from '@angular/google-maps';

@Component({
    selector: 'app-ride-tracking',
    templateUrl: './ride-tracking.component.html',
    styleUrls: ['./ride-tracking.component.less']
})
export class RideTrackingComponent implements OnInit {

    rideSummary: { rideId: string, pickupTime: string };

    error: string = null;

    zoom = 12;

    ti: TrackingInfo = null;
    location: { lat: number, lng: number } = null;

    protected map: any;

    shouldEnableDirection = false;
    srcLocation = null;
    destinationLocation = null;
    alreadyCentred = false;
    public directionsResults$: Observable<google.maps.DirectionsResult|undefined>;
    
    constructor(
        private activatedRoute: ActivatedRoute, 
        private webService: VehoWebService, 
        private titleService: Title,
        private mapDirectionsService: MapDirectionsService
        ) {
    }

    ngOnInit() {
        this.titleService.setTitle('Track your ride');
        this.activatedRoute.queryParams.subscribe(val => {
            // Decode params
            const pp = decodeURIComponent(val.q);
            try {
                const params = JSON.parse(atob(pp));
                this.error = null;

                if (!params || !params.rideId || !params.pickupTime) {
                    this.error = 'The page you are looking for could not be found.';
                } else {
                    this.rideSummary = params;
                }
            } catch (ex) {
                this.error = 'Something went wrong. Please try again after some time.';
            }
        });
    }

    mapReady(map) {
        this.map = map;
        if (this.rideSummary) {
            this.fetchDriverLocation();
            this.initTracking();
        }
    }

    initTracking() {
        const now = moment();
        const pickupTime = moment(this.rideSummary.pickupTime).local();

        const diff = pickupTime.diff(now, 'hour');
        if (diff > 1) {
            this.error = 'You can start tracking your ride 1 hour prior to pickup time. Please check back later.';
        } else {
            // Query every 20 seconds
            window.setInterval(this.fetchDriverLocation.bind(this), 30000);
        }
    }

    private handleError(error: any) {
        if (error && error.error) {
            this.error = error.error.message;
        } else {
            this.error = 'Something went wrong. Please try again after some time.';
        }

        return throwError(error);
    }

    private fetchDriverLocation() {
        if (!this.map) {
            return;
        }

        this.webService
            .getDriverLocation(this.rideSummary.rideId)
            .pipe(catchError(this.handleError.bind(this)))
            .subscribe(ti => {
                this.ti = ti;
                this.location = { lat: ti.latitude, lng: ti.longitude };
                if (!this.alreadyCentred) {
                    this.map.setCenter({ lat: ti.latitude, lng: ti.longitude });
                    this.alreadyCentred = true;
                }

                const pickupLocation = {
                    lat: ti.pickupLocation.latitude,
                    lng: ti.pickupLocation.longitude
                };

                const dropLocation = ti.dropLocation ? { lat: ti.dropLocation.latitude, lng: ti.dropLocation.longitude } : null;
                const driverLocation = { lat: ti.latitude, lng: ti.longitude };

                if (ti.segment === 'rental' && ['driverAssigned', 'clientLocated'].indexOf(ti.state) === -1) {
                    this.shouldEnableDirection = false;
                } else {
                    if (['driverAssigned', 'clientLocated'].indexOf(ti.state) !== -1) {
                        this.srcLocation = driverLocation;
                        this.destinationLocation = pickupLocation;
                        this.shouldEnableDirection = true;
                    } else if (['started', 'tripSheetGenerated', 'invoiceGenerated'].indexOf(ti.state) !== -1) {
                        this.srcLocation = driverLocation;
                        this.destinationLocation = dropLocation;
                        this.shouldEnableDirection = true;
                    } else {
                        this.shouldEnableDirection = false;
                    }
                    const request: google.maps.DirectionsRequest = {
                        destination: this.destinationLocation,
                        origin: this.srcLocation,
                        travelMode: google.maps.TravelMode.DRIVING
                      };
                    this.directionsResults$ = this.mapDirectionsService.route(request).pipe(map(response => response.result));
                }
            });
    }
}
