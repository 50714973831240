import { Component, OnInit } from '@angular/core';
import { RoutesRecognized, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { RoutesService, RouteInfo } from 'src/app/services/routes.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RemoteNotification } from 'src/app/models/notification';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.less']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];
    selectedHub: string;

    routes: RouteInfo[];

    constructor(public authenticationService: AuthenticationService, public notService: NotificationService, public routeService: RoutesService, private router: Router) {
        this.authenticationService.selectedHubAsObservable.subscribe(hub => {
            if (hub && hub.id !== this.selectedHub) {
                this.selectedHub = hub.id;
            }
        });
    }

    ngOnInit() {
        this.routeService.routes.subscribe(val => {
            this.routes = val.filter(v => v.showInSideBar !== false);
            this.menuItems = val.filter(m => m);
        });
    }

    isMobileMenu() {
        if (getWindowWidth() > 991) {
            return false;
        }
        return true;
    }

    selectHub(hubId: string) {
        if (hubId) {
            const hub = this.authenticationService.hubs.find(h => hubId === h.id);
            this.authenticationService.selectHub(hub);
        }
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigateByUrl('/signin');
    }

    getSideBarRoutes() {
        return this.routes.filter(item => item.showInSideBar !== false);
    }
}

function getWindowWidth() {
    return Math.max(
        document.documentElement['clientWidth'],
        document.body['scrollWidth'],
        document.documentElement['scrollWidth'],
        document.body['offsetWidth'],
        document.documentElement['offsetWidth']
    );
}
