import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { validatePhoneNumber } from '../_validators/phone-number.validator';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { requiredNotEmptyString } from '../_validators/requiredNotEmptyString';
import { User } from '../models/user';

declare var intlTelInput: any;
declare var intlTelInputUtils: any;

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.less']
})
export class OnboardingComponent implements OnInit {
    accountForm: FormGroup;
    returnUrl: string;
    itiInstance: any;
    isLoading = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private renderer: Renderer2,
        private authenticationService: AuthenticationService) { }

    initControls() {
        const input = document.querySelector('#phoneNumber');
        this.itiInstance = intlTelInput(input, {
            autoPlaceholder: 'aggressive',
            formatOnDisplay: true,
            initialCountry: 'IN',
            nationalMode: true,
            autoFormat: true,
            customPlaceholder: (selectedCountryPlaceholder, selectedCountryData) => {
                if (selectedCountryData.iso2 === 'in') {
                    return 'e.g. ' + selectedCountryPlaceholder.replace(/\D/g, '').substr(1, 10);
                } else {
                    return 'e.g. ' + selectedCountryPlaceholder.replace(/\D/g, '');
                }
            },
            preferredCountries: [ 'IN', 'US' ]
        });

        this.countryCode.setValue(`+${this.itiInstance.getSelectedCountryData().dialCode}`);
        const self = this;
        input.addEventListener('countrychange', function() {
            self.countryCode.setValue(`+${self.itiInstance.getSelectedCountryData().dialCode}`);
        });
    }

    ngOnInit() {
        this.accountForm = this.formBuilder.group({
            firstName: ['', requiredNotEmptyString],
            lastName: ['', requiredNotEmptyString],
            email: ['', [Validators.required, Validators.email]],
            countryCode: ['',
                Validators.required
            ],
            phoneNumber: ['', [
                Validators.required
            ]]
        }, { validator: validatePhoneNumber });

        this.initControls();
    }

    get phoneNumber() {
        return this.accountForm.get('phoneNumber');
    }

    get countryCode() {
        return this.accountForm.get('countryCode');
    }

    get firstName() {
        return this.accountForm.get('firstName');
    }

    get lastName() {
        return this.accountForm.get('lastName');
    }

    get email() {
        return this.accountForm.get('email');
    }

    getPhoneNumber() {
        return `${this.countryCode.value}-${this.phoneNumber.value}`;
    }

    setError(key: string) {
        const error = {};
        error[key] = true;
        this.accountForm.setErrors(error);
    }

    resetForm() {
        this.countryCode.enable();
        this.phoneNumber.enable();
    }

    handleError(error: any) {
        this.accountForm.enable();

        if ('NETWORK_ERROR' === error.errorCode) {
            this.setError('networkError');
        } else if (error.status === 409 && error.error.errorCode === 'EMAIL_OR_PHONE_EXISTS') {
            this.setError('userExists');
        }

        this.isLoading = false;
        return throwError(error);
    }

    createAccount() {
        const self = this;
        if (this.accountForm.valid) {
            this.isLoading = true;
            this.accountForm.disable();
            this.authenticationService.createAccount(
                this.firstName.value.trim(),
                this.lastName.value.trim(),
                this.email.value.trim(),
                this.phoneNumber.value.trim(),
                this.countryCode.value.trim().replace('+', '')
            ).pipe(catchError(this.handleError.bind(this)))
                .subscribe(resp => {
                    // Succeeded
                    this.isLoading = false;
                    this.requestLogin(resp);
                });
        }
    }

    requestLogin(user: User) {
        const ph = user.primaryPhone;
        const params = { cc: ph.countryCode, ph: ph.number };
        const qs = encodeURIComponent(btoa(JSON.stringify(params)));
        this.router.navigate(['/signin'], { queryParams: { q: qs } });
    }
}
