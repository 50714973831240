import { ProductImage } from "./vehicle";
import { RateCard } from "./rate-card";
import { FareEstimate } from "./fare-estimate";
import { TripSheet } from "./trip-sheet";

export class RideRequest {
    id: string;
    crn: string;
    customer: {
        name: string;
        email?: string;
        countryCode: string;
        phone: string;
    };
    additionalPassengers: RideRequest["customer"][];
    driver: {
        id: string;
        firstName: string;
        lastName: string;
        phone: { countryCode: string; number: string };
    };
    dropAddress: string;
    dropLatitude: number;
    dropLongitude: number;
    pickupAddress: string;
    pickupLatitude: number;
    pickupLongitude: number;
    pickupMode: "later" | "now";
    pickupTime: string;
    product: { id: string; displayName: string; primaryImage: ProductImage };
    returnTime: string;
    segment: "outstation" | "ondemand" | "rental" | "oneway";
    state:
        | "failed"
        | "queued"
        | "accepted"
        | "driverAssigned"
        | "clientLocated"
        | "started"
        | "tripSheetGenerated"
        | "invoiceGenerated"
        | "completed"
        | "cancelledByCustomer"
        | "cancelledByDriver"
        | "cancelledByProvider";
    vehicle: { id: string; color: string; licensePlate: string };
    vehicleCategory: { id: string; name: string };
    originalCategory?: { id: string; name: string };
    rateCard?: RateCard;
    fareEstimate?: FareEstimate;
    tripSheet?: TripSheet;
    events?: { name: string; timestamp: string; comment: string }[];
    comment?: string;
    _createdAt: string;
    hub: { ref: string };
    payments?: [];
    organization: { ref: string };
    type?: "corporate";
    account?: {
        id: string;
        companyName: string;
    };
    driverEstimate?: {
        totalDistance: number;
        totalTime: number;
        baseFare: number;
        netAmount: number;
        grandTotal: number;
        costPerDistance?: number;
        packageDistance?: number;
        packageTime?: number;
        packageAmount?: number;
        costPerExtraDistance?: number;
        costPerExtraTime?: number;
        minimumDistance?: number;
        driverAllowance?: number;
        comment?: string;
        rating?: string;
    };
}

export class RideEvent {
    id: string;
    crn: string;
    pickupAddress: string;
    dropAddress: string;
    pickupTime: string;
    returnTime: string;
    segment: "outstation" | "ondemand" | "rental" | "oneway";
    vehicleCategory: { id: string; name: string };
    state:
        | "failed"
        | "queued"
        | "accepted"
        | "driverAssigned"
        | "clientLocated"
        | "started"
        | "tripSheetGenerated"
        | "invoiceGenerated"
        | "completed"
        | "cancelledByCustomer"
        | "cancelledByDriver"
        | "cancelledByProvider";
}
