import {
    Component,
    OnInit,
    AfterViewInit,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { Location, PopStateEvent } from "@angular/common";
import { Subscription, throwError } from "rxjs";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { filter, catchError, mergeMapTo } from "rxjs/operators";
import { AuthenticationService } from "../services/authentication.service";
import { VehoWebService } from "../services/veho-web.service";
import { NotificationService, Alert } from "../services/notification.service";
import { MessagingService } from "../services/messaging.service";
import { RoutesService } from "../services/routes.service";
import { HttpParams } from "@angular/common/http";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Component({
    selector: "app-console",
    templateUrl: "./console.component.html",
    styleUrls: ["./console.component.less"],
})
export class ConsoleComponent implements OnInit, AfterViewInit {
    private _router: Subscription;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    private subscription: Subscription;

    alerts: Alert[] = [];

    buzzerFile = "/assets/media/buzzer";
    @ViewChild("buzzerContainer", { static: false })
    buzzerContainer: ElementRef;

    constructor(
        private messagingService: MessagingService,
        public location: Location,
        private router: Router,
        private authenticationService: AuthenticationService,
        private notificationService: NotificationService,
        private routesService: RoutesService,
        private vehoWebService: VehoWebService,
        private _snackBar: MatSnackBar
    ) {}

    openSnackBar(message: string, action: string, config?: MatSnackBarConfig) {
        this._snackBar.open(message, action, config);
    }

    handleError(error: any) {
        return throwError(error);
    }

    ngOnInit() {
        this.notificationService.alerts.subscribe((alert) => {
            if (alert) {
                this.openSnackBar(alert.message, "close", { duration: 3000 });
            }
        });
        const token = this.authenticationService.currentToken;
        if (
            token &&
            (!this.authenticationService.currentOrg ||
                !this.authenticationService.currentOrg.activated)
        ) {
            this.vehoWebService
                .getOrgs(token.accessToken, null)
                .pipe(catchError(this.handleError.bind(this)))
                .subscribe((resp) => {
                    if (resp.items.length === 0) {
                        // alert('You do not have any organizations under this account.');
                        // this.authenticationService.logout();
                        // this.router.navigateByUrl('/');
                        // this.router.navigate(['signin']);
                        // Redirect to create organization screen
                        this.routesService.setOnboardingMenu();
                        this.router.navigate(["orgs"]);
                    } else {
                        if (!resp.items[0].activated) {
                            this.routesService.setPendingActivationMenu();
                        } else {
                            this.authenticationService.currentOrg =
                                resp.items[0];
                            this.routesService.setDefaultMenu();
                            this.getHubs();
                        }
                    }
                });
        } else if (token) {
            if (this.authenticationService.currentOrg.activated) {
                this.routesService.setDefaultMenu();
                this.getHubs();
            } else {
                this.routesService.setPendingActivationMenu();
            }
        } else {
            this.router.navigateByUrl("/signin");
        }

        this.messagingService.requestPermission();

        this.messagingService.messages.subscribe((message) => {
            if (message) {
                this.notificationService.showPushNotification(message);

                if (message.event === "ride_created") {
                    this.playBuzzerAudio();
                }
            }
        });

        this.notificationService.load();
    }

    playBuzzerAudio() {
        const mpegSource =
            '<source src="' + this.buzzerFile + '.mpeg" type="audio/mpeg">';
        this.buzzerContainer.nativeElement.innerHTML =
            '<audio autoplay="autoplay">' + mpegSource + "</audio>";
    }

    getHubs() {
        const token = this.authenticationService.currentToken;
        this.vehoWebService
            .getHubs(
                token.accessToken,
                this.authenticationService.currentOrg.id,
                null
            )
            .pipe(catchError(this.handleError.bind(this)))
            .subscribe((resp) => {
                if (resp.items.length === 0) {
                    alert(
                        "You do not have any cities added to your organization."
                    );
                    this.router.navigateByUrl("/hubs");
                    this.routesService.setNoHubMenu();
                    // this.authenticationService.logout();
                    // this.router.navigateByUrl('/');
                } else {
                    this.authenticationService.hubs = resp.items;
                    this.authenticationService.selectHub(resp.items[0]);
                    this.routesService.setDefaultMenu();
                }
            });
    }

    ngAfterViewInit() {
        this.runOnRouteChange();
    }

    runOnRouteChange(): void {}

    isMac(): boolean {
        let bool = false;
        if (
            navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
            navigator.platform.toUpperCase().indexOf("IPAD") >= 0
        ) {
            bool = true;
        }
        return bool;
    }

    closeAlert(alert: Alert) {
        const index = this.alerts.indexOf(alert);
        if (index > -1) {
            // Remove alert
            this.alerts.splice(index, 1);
        }
    }
}
