import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ConsoleComponent } from "./console/console.component";
import { ComponentsModule } from "./components/components.module";
import { VehoWebService } from "./services/veho-web.service";
import { HttpClientModule } from "@angular/common/http";
import { AuthenticationService } from "./services/authentication.service";
import { ImageGalleryComponent } from "./components/image-gallery/image-gallery.component";
import { ListViewRendererComponent } from "./components/list-view/list-view-renderer/list-view-renderer.component";
import { NgbAlertModule } from "@ng-bootstrap/ng-bootstrap";
import { MapsLoaderComponent } from "./components/places-search-form-control/maps-loader/maps-loader.component";
import { getMessaging, provideMessaging } from "@angular/fire/messaging";
import { environment } from "../environments/environment";
import { OnboardingComponent } from "./onboarding/onboarding.component";
import { RoutesService } from "./services/routes.service";
import { RideTrackingComponent } from "./ride-tracking/ride-tracking.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { NotifierModule } from "angular-notifier";
import { RideTrackingShortComponent } from "./ride-tracking-short/ride-tracking-short.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { DriverDetailsComponent } from "./drivers/driver-details/driver-details.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { DailyCommuteComponent } from "./daily-commute/daily-commute.component";
import { FormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CommuteDetailsComponent } from "./daily-commute/commute-details/commute-details.component";
import { CorporateImageComponent } from "./corporate-image/corporate-image.component";
import { BusinessDetailsDialogComponent } from "./business/business-details-dialog/business-details-dialog.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        PageNotFoundComponent,
        ConsoleComponent,
        ListViewRendererComponent,
        OnboardingComponent,
        RideTrackingComponent,
        RideTrackingShortComponent,
        DriverDetailsComponent,
        DailyCommuteComponent,
        CommuteDetailsComponent,
        CorporateImageComponent,
        BusinessDetailsDialogComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ComponentsModule,
        NgbAlertModule,
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideMessaging(() => getMessaging()),
        //AngularFireModule.initializeApp(environment.firebaseConfig),
        //AngularFireMessagingModule,
        MatExpansionModule,
        NotifierModule,
        GoogleMapsModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        FormsModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
    ],
    providers: [VehoWebService, AuthenticationService, RoutesService],
    bootstrap: [AppComponent],
    entryComponents: [
        ImageGalleryComponent,
        ListViewRendererComponent,
        MapsLoaderComponent,
    ],
})
export class AppModule {}
