<div class="main-content">
    <div class="container-fluid">
        <h5>Name : {{ fname }} {{ lname }}</h5>
        <h5>Number : {{ coutnry }} {{ number }}</h5>
        <mat-tab-group
            class="ride-state-tabs"
            mat-stretch-tabs
            backgroundColor="primary"
            color="accent"
        >
            <mat-tab
                *ngFor="let tab of tabs"
                [label]="tab.displayName"
            ></mat-tab>
        </mat-tab-group>
        <div class="table-wrapper">
            <div class="loading-shade">
                <mat-spinner *ngIf="isLoading" diameter="32"></mat-spinner>
                <div *ngIf="hasError">
                    <div class="error-message">
                        <mat-icon class="mr-2 text-danger">error</mat-icon>
                        <span>{{ errorMessage }}</span>
                    </div>
                    <div class="text-center">
                        <button
                            type="button"
                            class="retry-button"
                            mat-raised-button
                            color="primary"
                            (click)="retryRequest()"
                        >
                            Retry
                        </button>
                    </div>
                </div>
            </div>

            <div class="table-container" #viewport>
                <table
                    mat-table
                    [dataSource]="driverDetails"
                    class="rides-table"
                    multiTemplateDataRows
                >
                    <ng-container matColumnDef="hub">
                        <th mat-header-cell *matHeaderCellDef>City</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.hub.name }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="crn">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                            disableClear
                        >
                            CRN
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.crn }}</td>
                    </ng-container>

                    <ng-container matColumnDef="segment">
                        <th mat-header-cell *matHeaderCellDef>Segment</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.segment }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="customerName">
                        <th mat-header-cell *matHeaderCellDef>Customer</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.customer.name }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="pickupDate">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                            disableClear
                        >
                            Pickup Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.pickupTime | date }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="pickupTime">
                        <th mat-header-cell *matHeaderCellDef>Pickup Time</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.pickupTime | date : "hh:mm a" }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="pickupLocation">
                        <th mat-header-cell *matHeaderCellDef>
                            Pickup Location
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.pickupAddress }}
                        </td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky: false"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="
                            let row;
                            let index = index;
                            columns: displayedColumns
                        "
                        class="ride-row"
                        [class.expanded-row]="expandedRide === row"
                        (click)="toggleExpandedRow(index, row)"
                    ></tr>
                </table>
            </div>
        </div>
        <mat-paginator
            [pageSizeOptions]="[10, 20, 50]"
            [length]="total"
            [pageSize]="pageSize"
            [pageIndex]="currentPage"
            (page)="onPageSizeChange($event)"
        >
        </mat-paginator>
    </div>
</div>
